import "./set-public-path";
import "./plugins/fetching";
// import "./plugins/fcm";
import "./assets/css/style.css";
import "./assets/css/main.css";
import "./assets/css/common.css";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import json5 from "json5";
import singleSpaVue from "single-spa-vue";
import Vue from "vue";

import pjson from "../package.json";

import App from "./App.vue";
import { i18n } from "./plugins/i18n";
import router from "./router";
import store from "./store";
import "./plugins/vuetify";

Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {}
      });
    },
    created: function() {
      Vue.prototype.$token = this.idToken;
      Vue.prototype.$logout = this.logout;
      Vue.prototype.$noNavBar = this.noNavBar;
      Vue.prototype.$serverOrigin = this.serverOrigin;
      // Vue.prototype.$firebaseAPIKey = this.firebaseAPIKey;
      // Vue.prototype.$firebaseProjectId = this.firebaseProjectId;
      // Vue.prototype.$firebaseSenderID = this.firebaseSenderID;
      // Vue.prototype.$firebaseAppId = this.firebaseAppId;
      // Vue.prototype.$firebasePublicKey = this.firebasePublicKey;
      Vue.prototype.$sentryUrl = this.sentryUrl;

      const lang = this.$route.path.slice(1, 3);
      if (lang === "en" || lang === "ar") {
        this.$i18n.locale = lang;
      }
      this.setLang();
      this.$vuetify.rtl = this.$i18n.locale === "ar";
      this.$vuetify.theme["nfo-main-color"] = "F77C1A";
      this.$vuetify.theme["fo-main-color"] =
        this.foodOrderingColor || "#f77c1a";

      Sentry.setTag("microfrontend", "navigation");

      Sentry.init({
        dsn: this.sentryUrl,
        integrations: [
          new Integrations.Vue({ Vue, attachProps: true, logErrors: true })
        ],
        release: `navigation@${pjson.version}`,
        tracesSampleRate: this.sentrySampleRate,
        ignoreErrors: this.ignoredSentryErrors,
        beforeSend: this.checkSentryErrors
      });
    },
    watch: {
      "$i18n.locale": function() {
        this.$vuetify.rtl = this.$i18n.locale === "ar";
      },
      eventData: {
        immediate: true,
        handler() {
          if (this.eventData.length) {
            this.showMessage();
          }
        }
      }
    },
    methods: {
      setLang() {
        const translations = this.translations();
        this.$i18n.setLocaleMessage("en", translations.en);
        this.$i18n.setLocaleMessage("ar", {
          ...this.arabicTranslation,
          ...translations.ar
        });
      },
      showMessage() {
        let event =
          json5.parse(this.eventData.find(eventData => eventData)) || {};
        if (event.content) {
          this.$root.$emit("snackbar", {
            text: event.content.message,
            color: event.content.alert,
            link: event.content.data.file
          });
        }
      }
    },
    router,
    i18n,
    store
  }
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
